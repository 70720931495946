import { NextPage } from 'next';

import { fetchSEOTags } from '../../api/services';
import InspectRequestForm from '../../components/CarInspection/InspectRequestForm';
import RequestsLayout from '../../components/Layout/RequestsLayout';
import ServicesIndicator from '../../components/ServicesIndicator';
import IWithSEO from '../../types/IWithSEO';

const CarInspection: NextPage<IWithSEO> = ({ seo }) => {
  return (
    <RequestsLayout seo={seo}>
      <div className="alignfull -mt-8">
        <ServicesIndicator />
      </div>
      <InspectRequestForm />
    </RequestsLayout>
  );
};

export async function getStaticProps() {
  return {
    props: {
      seo: await fetchSEOTags('/'),
    },
  };
}

export default CarInspection;
