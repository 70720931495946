/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

import { applyCouponCode, processInspectionCheckout, removeCoupons } from '../../../api/services';
import { numberFormat } from '../../../helpers/formatters';
import Icon from '../../common/Icon';
import CheckBox from '../../Forms/Checkbox';
import { useInspectRequestData } from '../InspectRequestProvider';
import IOrderItem from '../IOrderItem';

interface ICheckoutProps {
  setStep(step: number): void;
}

const Checkout = ({ setStep }: ICheckoutProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [couponEnabled, setCouponEnabled] = useState<boolean>(false);
  const [couponCode, setCouponCode] = useState<string>('');
  const [chosenGateWay, setChosenGateWay] = useState<string>('');
  const { order, setOrder } = useInspectRequestData();

  console.info(order);

  useEffect(() => {
    if (order && order.available_gateways && order.available_gateways[0]) {
      setChosenGateWay(order.available_gateways[0].id);
    }
  }, [order]);

  return order ? (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        setSubmitting(true);

        processInspectionCheckout({ gateway: chosenGateWay }).then(
          ({ data }) => {
            setSubmitting(false);
            if (data.redirect) {
              window.location.href = data.redirect;
            }
          },
          ({ response }) => {
            setSubmitting(false);
            toast.error(response.data.message);
          },
        );
      }}
      className="relative"
    >
      {submitting && <div className="blockUI" />}
      <div className="flex items-center mb-7">
        {/* <button type="button">
          <Icon name="expand" className="w-8 h-8 transform-gpu -rotate-90" />
        </button> */}
        <h2 className="text-xl mx-auto leading-none text-center font-black text-dark-800">تایید نهایی و پرداخت</h2>
      </div>
      <table className="w-full mb-5 bill-table">
        <tbody>
          {order.items.map((orderItem: IOrderItem) => (
            <tr key={orderItem.id}>
              <td>{orderItem.name}</td>
              <td>
                <div className="price">
                  {numberFormat(orderItem.amount)} <small>تومان</small>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>جمع</td>
            <td>
              <div className="price">
                {numberFormat(order.subtotal)} <small>تومان</small>
              </div>
            </td>
          </tr>

          {/* <tr className="font-bold">
            <td>تخفیف</td>
            <td>
              <div className="price">
                {numberFormat(order.discount)} <small>تومان</small>
              </div>
            </td>
          </tr> */}
        </tfoot>
      </table>

      <div className="border-b border-b-neutral-200 pb-6 mb-6">
        {order.applied_coupons.length > 0 ? (
          <div className="flex justify-center items-center text-base">
            <div className="">
              کد تخفیف:‌ {order.applied_coupons[0]}
              <div className="text-sm text-neutral-500">
                <span className="price ">{numberFormat(order.discount)} تومان</span> از مبلغ کل کسر شد
              </div>
            </div>
            <button
              className="mr-auto text-base font-bold px-2 h-8 bg-red-500 text-white text-center rounded-lg shadow-md"
              type="button"
              onClick={() => {
                setSubmitting(true);
                removeCoupons().then(
                  ({ data }) => {
                    setSubmitting(false);
                    setOrder(data);
                    toast.success('تخفیف های اعمال شده حذف شد');
                  },
                  ({ response }) => {
                    setSubmitting(false);
                    toast.error(response.data.message);
                  },
                );
              }}
            >
              حذف کد تخفیف
            </button>
          </div>
        ) : (
          <>
            <label className="text-lg mb-3 flex items-center cursor-pointer font-medium text-dark-340">
              <CheckBox
                checked={couponEnabled}
                onChange={() => setCouponEnabled(!couponEnabled)}
                value="yes"
                className="ml-3"
              />
              کد تخفیف دارید؟
            </label>

            <div className={`flex ${couponEnabled ? '' : 'opacity-30'}`}>
              <input
                type="text"
                className="h-14 flex-1 min-w-0 bg-neutral-100 rounded-lg px-5"
                placeholder="کد تخفیف را وارد کنید"
                value={couponCode}
                disabled={!couponEnabled}
                onChange={(e) => {
                  setCouponCode(e.target.value);
                }}
              />
              <button
                onClick={() => {
                  const toastId = toast.loading('در حال بررسی کد تخفیف');

                  setSubmitting(true);
                  applyCouponCode(couponCode).then(
                    ({ data }) => {
                      setSubmitting(false);
                      setOrder(data);
                      toast.success('کد تخفیف با موفقیت اعمال شد', { id: toastId });
                    },
                    ({ response }) => {
                      setSubmitting(false);
                      toast.error(response.data.message, { id: toastId });
                    },
                  );
                }}
                disabled={!couponEnabled}
                className="mr-4 px-5 bg-primary text-center rounded-lg shadow-md"
                type="button"
              >
                اعمال تخفیف
              </button>
            </div>
          </>
        )}
      </div>

      <div className="border-b border-b-neutral-200 pb-6 mb-6">
        <h3 className="text-base font-bold mb-3">انتخاب روش پرداخت</h3>
        <ul className="gateways">
          {order.available_gateways.map((gateway) => (
            <li
              onClick={() => setChosenGateWay(gateway.id)}
              key={gateway.id}
              className={`flex cursor-pointer px-5 items-center h-16 rounded-xl ${
                chosenGateWay == gateway.id ? 'active border-green-400 border-2' : 'border border-neutral-300'
              }`}
            >
              <img src={gateway.icon} alt={gateway.label} className="w-auto max-h-11" />
              <div className="flex-1 text-neutral-600 px-4 min-w-0">
                <span className="block font-bold mb-1 text-sm">پرداخت آنلاین</span>
                <span className="block text-xs">درگاه {gateway.label}</span>
              </div>
              <span className="mr-auto">
                <Icon name="check-circle" className="w-6 h-6 text-green-400" />
              </span>
            </li>
          ))}
        </ul>
      </div>

      <div className="text-green-400 text-2xl font-bold text-center mb-5">
        <span className="block text-lg">مبلغ قابل پرداخت:</span>
        <div className="price">
          {numberFormat(order.total)} <small>تومان</small>
        </div>
      </div>

      <div className="flex">
        <button type="button" onClick={() => setStep(4)} className="button cancel primary right block w-1/2">
          بازگشت
        </button>
        <button disabled={submitting} type="submit" className="button primary left block w-1/2">
          ثبت درخواست
        </button>
      </div>
    </form>
  ) : null;
};

export default Checkout;
