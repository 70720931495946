import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';

import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import jalaliday from 'jalaliday';
import { useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import styled from 'styled-components';
import SwiperCore, { A11y, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { inspectOrderSchedule } from '../../../api/services';
import Icon from '../../common/Icon';
import { useInspectRequestData } from '../InspectRequestProvider';

SwiperCore.use([Navigation, A11y]);

dayjs.extend(weekday);
dayjs.extend(jalaliday);

const date = dayjs();
const today = date.calendar('jalali').locale('fa');
const jalaliDate = date.calendar('jalali').locale('fa');

const SSliderWrap = styled.div`
  --swiper-navigation-size: 24px;
  --swiper-theme-color: #333;
  position: relative;

  .swiper-button-next {
    right: -20px;
  }

  .swiper-button-prev {
    left: -20px;
  }
`;

interface IPickTimeProps {
  setStep(step: number): void;
}

const availableDays = () => {
  const days = [];

  for (let i = 0; i < 10; i++) {
    const day = jalaliDate.add(i, 'days');

    if (day.weekday() === 5 || day.weekday() === 6) continue;

    days.push(day);
  }

  return days;
};

const PickTime = ({ setStep }: IPickTimeProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [chosenDay, setChosenDay] = useState<string>('');
  const [chosenHour, setChosenHour] = useState<string>('');
  const daysNextBtn = useRef<HTMLButtonElement>(null);
  const daysPrevBtn = useRef<HTMLButtonElement>(null);

  const { setOrder } = useInspectRequestData();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        if (!chosenDay) {
          toast.error('لطفا روز اعزام کارشناس را انتخاب کنید');
          return;
        }

        if (!chosenHour) {
          toast.error('لطفا ساعت اعزام کارشناس را انتخاب کنید');
          return;
        }

        setSubmitting(true);
        inspectOrderSchedule({ day: chosenDay, hour: chosenHour }).then(
          ({ data }) => {
            setSubmitting(false);

            setOrder(data);
            setStep(5);
          },
          ({ response }) => {
            setSubmitting(false);
            toast.error(response.data.message);
          },
        );
      }}
      className="relative"
    >
      <h2 className="text-2xl leading-none mb-7 text-center font-black text-dark-800">
        انتخاب روز و ساعت اعزام کارشناس
      </h2>

      <p className="text-center text-base mb-3">
        لطفا روز و ساعت اعزام کارشناس را انتخاب کنید یا در صورتی که نمیتوانید اکنون زمان دقیق را مشخص کنید بر روی گزینه
        بعدا هماهنگ میکنم کلیک کنید
      </p>

      <button
        className="mx-auto bg-neutral-700 text-white rounded-2xl px-4 py-4 shadow-gray font-bold table mb-10"
        type="button"
        onClick={() => {
          setStep(5);
        }}
      >
        بعدا زمان را هماهنگ میکنم
      </button>

      <h3 className="text-2xl font-bold text-center mb-1">
        انتخاب روز <small className="font-light text-base">(شنبه تا چهارشنبه)</small>
      </h3>

      <SSliderWrap className="select-none">
        <button type="button" className="swiper-button-next" ref={daysNextBtn} />
        <button type="button" className="swiper-button-prev" ref={daysPrevBtn} />
        <Swiper
          navigation={{
            prevEl: daysPrevBtn.current,
            nextEl: daysNextBtn.current,
          }}
          slidesPerView={3}
          className="mb-10"
        >
          {availableDays().map((day, i) => (
            <SwiperSlide key={i} className="p-3 !h-auto">
              <div
                className={`border cursor-pointer h-full bg-white relative w-full border-neutral-200 shadow-sm p-4 rounded-2xl flex flex-col items-center justify-center ${
                  chosenDay === day.format('DD-MM-YYYY') &&
                  'border-green-400 text-green-700 ring-4 ring-green-200 ring-opacity-40'
                }`}
                onClick={() => {
                  setChosenDay(day.format('DD-MM-YYYY'));
                }}
              >
                {day.date() === today.date() && <span className="text-sm text-purple-600 font-bold">امروز</span>}
                {day.date() === today.clone().add(1, 'days').date() && (
                  <span className="text-sm text-purple-600 font-bold">فردا</span>
                )}
                {day.date() === today.clone().add(2, 'days').date() && (
                  <span className="text-sm text-purple-600 font-bold">پس فردا</span>
                )}

                <strong className="text-lg mt-auto font-bold mb-3">{day.format('dddd')}</strong>

                <span className="text-lg font-normal text-neutral-600">{day.format('D MMMM')}</span>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </SSliderWrap>

      <h3 className="text-2xl font-bold text-center mb-1">انتخاب ساعت</h3>

      <Swiper slidesPerView={3} className="mb-10">
        <SwiperSlide className="py-3 px-1">
          <div
            onClick={() => {
              setChosenHour('9-12');
            }}
            className={`border cursor-pointer bg-white relative w-full border-neutral-200 shadow-sm p-4 rounded-2xl flex flex-col items-center justify-center ${
              chosenHour === '9-12' && 'border-green-400 text-green-700 ring-4 ring-green-200 ring-opacity-40'
            }`}
          >
            <Icon name="morning" className="w-8 h-8 text-primary text-opacity-60 mb-2" />
            <strong className="text-lg font-bold mb-3">صبح</strong>

            <span className="text-base font-normal text-neutral-600">9 تا 12 صبح</span>
          </div>
        </SwiperSlide>

        <SwiperSlide className="py-3 px-1">
          <div
            onClick={() => {
              setChosenHour('12-16');
            }}
            className={`border cursor-pointer bg-white relative w-full border-neutral-200 shadow-sm p-4 rounded-2xl flex flex-col items-center justify-center ${
              chosenHour === '12-16' && 'border-green-400 text-green-700 ring-4 ring-green-200 ring-opacity-40'
            }`}
          >
            <Icon name="noon" className="w-8 h-8 text-primary text-opacity-60 mb-2" />
            <strong className="text-lg font-bold mb-3">ظهر</strong>

            <span className="text-base font-normal text-neutral-600">12 تا 4 عصر</span>
          </div>
        </SwiperSlide>

        <SwiperSlide className="py-3 px-1">
          <div
            onClick={() => {
              setChosenHour('16-20');
            }}
            className={`border cursor-pointer bg-white relative w-full border-neutral-200 shadow-sm p-4 rounded-2xl flex flex-col items-center justify-center ${
              chosenHour === '16-20' && 'border-green-400 text-green-700 ring-4 ring-green-200 ring-opacity-40'
            }`}
          >
            <Icon name="evening" className="w-8 h-8 text-primary text-opacity-60 mb-2" />
            <strong className="text-lg font-bold mb-3">عصر</strong>

            <span className="text-base font-normal text-neutral-600">4 عصر تا 8 شب</span>
          </div>
        </SwiperSlide>
      </Swiper>

      <div className="flex">
        <button type="button" onClick={() => setStep(3)} className="button cancel primary right block w-1/2">
          بازگشت
        </button>
        <button disabled={submitting} type="submit" className="button primary left block w-1/2">
          ثبت درخواست
        </button>
      </div>
    </form>
  );
};

export default PickTime;
