import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { mutate } from 'swr';

import fetchAPI from '../../../api';
import { sendInspectionRequest } from '../../../api/services';
import { isPhoneValid } from '../../../helpers/validationHelpers';
import Icon from '../../common/Icon';
import CarSelect from '../../Forms/CarSelect';
import MapSelector from '../../Forms/MapSelector';
import PageModal from '../../PageModal';
import Modal from '../../Widgets/Modal';
import { useInspectRequestData } from '../InspectRequestProvider';

interface IInitOrderProps {
  setStep(step: number): void;
}

const InitOrder = ({ setStep }: IInitOrderProps) => {
  const [carSelectVisible, setCarSelectVisible] = useState<boolean>(false);
  const [mapSelectVisible, setMapSelectVisible] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [name: string]: any }>({});
  const { fullName, setFullName, phone, setPhone, sellerPhone, setSellerPhone, carModel, setCarModel, address } =
    useInspectRequestData();

  const showErrorMessage = (message: string) => {
    return <p className="text-sm font-medium text-red-600 pt-2 px-5">{message}</p>;
  };

  // Preload car models to build a faster experience
  useEffect(() => {
    setTimeout(() => {
      mutate(
        '/cardan/v1/car-models',
        fetchAPI('/cardan/v1/car-models').then(({ data }) => data || []),
      );
    }, 500);
  }, []);

  const validateForm = () => {
    const errs: { [name: string]: string } = {};

    if (!fullName) {
      errs.fullName = 'لطفا نام و نام خانوادگی خود را وارد کنید';
    }

    if (fullName && !fullName.match(/^[\u0600-\u06FF\s]+$/)) {
      errs.fullName = 'لطفا نام و نام خانوادگی خود را به فارسی وارد کنید';
    }

    if (fullName && fullName.match(/[\u0660-\u0669\u06f0-\u06f9]/g)) {
      errs.fullName = 'نام و نام خانوادگی شما نمیتواند شامل اعداد باشد';
    }

    if (!phone) {
      errs.phone = 'لطفا شماره همراه خود را وارد کنید';
    }

    if (phone && !isPhoneValid(phone)) {
      errs.phone = 'شماره همراه به صورت صحیح وارد نشده است';
    }

    if (sellerPhone && !isPhoneValid(sellerPhone)) {
      errs.sellerPhone = 'شماره همراه فروشنده به صورت صحیح وارد نشده است';
    }

    if (!carModel) {
      errs.carModel = 'لطفا مدل خودرو را انتخاب کنید';
    }

    if (!address.address) {
      errs.address = 'لطفا آدرس و موقعیت خود را انتخاب کنید';
    }

    return errs;
  };

  const removeError = (field: string) => {
    const errs = errors;

    if (errs[field]) {
      delete errs[field];
    }

    setErrors(errs);
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        const errs = validateForm();

        if (Object.keys(errs).length === 0) {
          setSubmitting(true);
          sendInspectionRequest({
            fullName,
            phone,
            sellerPhone,
            address,
            carModel,
          }).then(
            ({ data }) => {
              setSubmitting(false);
              if (data.needs_otp_verification) {
                setStep(2);
              }
            },
            ({ response }) => {
              setSubmitting(false);
              toast.error(response.data.message);
            },
          );
        } else {
          setErrors(errs);
        }
      }}
    >
      <h2 className="text-3xl leading-none mb-2.5 text-center font-black text-dark-800">درخواست کارشناسی خودرو</h2>

      <PageModal
        render={(clickHandler) => (
          <a
            href="/car-inspection-terms"
            onClick={clickHandler}
            className="block text-center leading-none mb-5 text-base font-black text-green-400"
          >
            توضیحات و شرایط
          </a>
        )}
        slug="car-inspection-terms"
      />

      <div className="form-row mb-5">
        <div className="primary-field">
          <span className="icn">
            <Icon name="user" />
          </span>
          <input
            type="text"
            value={fullName}
            className="placeholder-neutral-350"
            onChange={(e) => {
              removeError('fullName');
              setFullName(e.target.value);
            }}
            placeholder="نام و نام خانوادگی"
          />
        </div>

        {errors.fullName && showErrorMessage(errors.fullName)}
      </div>
      <div className="form-row mb-5">
        <div className="primary-field">
          <span className="icn">
            <Icon name="phone" />
          </span>
          <input
            type="text"
            value={phone}
            className="placeholder-neutral-350"
            pattern="[0-9]*"
            inputMode="numeric"
            onChange={(e) => {
              removeError('phone');
              setPhone(e.target.value);
            }}
            placeholder="موبایل (جهت احراز هویت و ارسال کد تایید)"
          />
        </div>
        {errors.phone && showErrorMessage(errors.phone)}
      </div>

      <div className="form-row mb-5">
        <div className="primary-field">
          <span className="icn">
            <Icon name="phone-person" />
          </span>
          <input
            type="text"
            value={sellerPhone}
            className="placeholder-neutral-350"
            pattern="[0-9]*"
            inputMode="numeric"
            onChange={(e) => {
              removeError('sellerPhone');
              setSellerPhone(e.target.value);
            }}
            placeholder="شماره فروشنده (اختیاری)"
          />
        </div>
        {errors.sellerPhone && showErrorMessage(errors.sellerPhone)}
      </div>

      <div className="form-row mb-5">
        <div
          className="primary-field cursor-pointer select-none"
          onClick={() => {
            removeError('carModel');
            setCarSelectVisible(true);
          }}
        >
          <span className="icn">
            <Icon name="car" />
          </span>
          <div className="field">
            {carModel ? (
              <span className="text-lg text-neutral-500 whitespace-nowrap overflow-hidden overflow-ellipsis">
                {carModel.name}
              </span>
            ) : (
              'انتخاب خودرو'
            )}
          </div>

          <span className="absolute w-11.5 h-11.5 inset-y-2.5 left-2.5 rounded-full inline-flex items-center justify-center bg-primary">
            <Icon name="expand" className="w-6 h-6 text-white" />
          </span>
        </div>
        {errors.carModel && showErrorMessage(errors.carModel)}
        {carSelectVisible && (
          <Modal
            title={<span className="font-bold text-dark-800 text-lg">انتخاب مدل، برند و تیپ خودرو</span>}
            width="440px"
            visible={carSelectVisible}
            toggle={setCarSelectVisible}
          >
            <CarSelect
              onChange={(carModel) => {
                setCarModel(carModel);
                setCarSelectVisible(false);
              }}
            />
          </Modal>
        )}
      </div>

      {/* <div className="form-row mb-5">
          <div className="primary-field cursor-pointer select-none">
            <span className="icn">
              <Icon name="car" />
            </span>
            <div className="field">انتخاب مدل</div>

            <span className="absolute w-11.5 h-11.5 inset-y-2.5 left-2.5 rounded-full inline-flex items-center justify-center bg-primary">
              <Icon name="expand" className="w-6 h-6 text-white" />
            </span>
          </div>
        </div> */}

      <div className="form-row mb-5">
        <div
          className="primary-field cursor-pointer select-none"
          onClick={() => {
            removeError('address');
            setMapSelectVisible(true);
          }}
        >
          <span className="icn">
            <Icon name="marker" />
          </span>
          <div className="field flex-1 whitespace-nowrap overflow-hidden overflow-ellipsis pl-16 min-w-0">
            {address.address ? (
              <span className="text-lg text-neutral-500 whitespace-nowrap overflow-hidden overflow-ellipsis">
                {address.address}
              </span>
            ) : (
              'آدرس و لوکیشن'
            )}
          </div>

          <span className="absolute w-11.5 h-11.5 inset-y-2.5 left-2.5 rounded-full inline-flex items-center justify-center bg-primary">
            <Icon name="map-colored" className="w-6 h-6 stroke-white" />
          </span>
        </div>

        {errors.address && showErrorMessage(errors.address)}

        {mapSelectVisible && (
          <Modal
            title={
              <div>
                <h4 className="text-lg font-bold text-dark-800">انتخاب آدرس</h4>
                <p className="text-sm text-dark-340">موقعیت مکانی خود را مشخص کنید</p>
              </div>
            }
            width="600px"
            visible={mapSelectVisible}
            toggle={setMapSelectVisible}
          >
            <MapSelector
              onFinish={() => {
                setMapSelectVisible(false);
              }}
            />
          </Modal>
        )}
      </div>

      <button
        disabled={submitting}
        data-loading={submitting || undefined}
        type="submit"
        className="button primary block w-full"
      >
        ثبت درخواست
      </button>
    </form>
  );
};

export default InitOrder;
