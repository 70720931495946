// disable-typescript
import 'leaflet/dist/leaflet.css';

import { LatLngLiteral } from 'leaflet';
import dynamic from 'next/dynamic';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { getAddressReverse } from '../../api/services';
import { useInspectRequestData } from '../CarInspection/InspectRequestProvider';
import SVGLoader from '../common/SVGLoader';
import { IMapProps } from '../Map/Map';
import type { ISearchableSelectOptionProps } from './SearchableSelect';
import SearchableSelect from './SearchableSelect';

export interface IAddress {
  latLng: LatLngLiteral | null;
  address: string;
  state: string;
  city: string;
}

interface ICity {
  name: string;
}

interface IProvince {
  province: string;
  cities: ICity[];
}

interface IMapSelectorProps {
  onFinish?: () => void;
}

const MapComponent = dynamic<IMapProps>(() => import('../Map').then((module) => module.default), {
  ssr: false,
});

const MapSelector = ({ onFinish }: IMapSelectorProps) => {
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const { address, setAddress } = useInspectRequestData();
  const [provinces, setProvinces] = useState<ISearchableSelectOptionProps[]>([]);
  const [cities, setCities] = useState<ISearchableSelectOptionProps[]>([]);

  const setProvince = (province: string) => {
    setAddress({ ...address, state: province });
  };

  const setCity = (city: string) => {
    setAddress({ ...address, city });
  };

  const setAddressDetails = (details: string) => {
    setAddress({ ...address, address: details });
  };

  useEffect(() => {
    !(async () => {
      const allProvinces = (await import('../util/iran-cities.json')).default;
      setProvinces(
        allProvinces.map((prov) => {
          return {
            label: prov.province,
            value: prov.province,
            cities: prov.cities,
            disabled: prov.disabled || false,
          };
        }),
      );
    })();
  }, []);

  useEffect(() => {
    setAddress({
      latLng: { lat: 0, lng: 0 },
      address: '',
      city: '',
      state: '',
    });
  }, [setAddress]);

  useEffect(() => {
    if (address.latLng && address.latLng.lat && address.latLng.lng) {
      // setLoading(true);
      getAddressReverse(address.latLng).then(
        ({ data }) => {
          setLoading(false);
          const provinceObj = provinces.find((prov) => data.state.match(prov.value));
          if (provinceObj && provinceObj.cities) {
            setCities(
              provinceObj.cities.map((city: any) => ({
                label: city.name,
                value: city.name,
              })),
            );
          }
          setAddress({
            ...address,
            state: data.state.replace('استان ', ''),
            city: data.city,
            address: data.formatted_address.replace(data.state + '، ', ''),
          });
        },
        () => {
          setLoading(false);
          setAddress({
            ...address,
            state: '',
            city: '',
            address: '',
          });
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address.latLng]);

  useEffect(() => {
    setMounted(true);
  }, []);

  console.info(address);

  const setLatLng = useCallback(
    (latLng: LatLngLiteral) => {
      setAddress({ ...address, latLng });
    },
    [address, setAddress],
  );

  const MemoMap = useMemo(() => {
    return (
      <MapComponent
        onChange={(latLng) => {
          setLatLng(latLng);
        }}
        center={{ lat: 35.7031748, lng: 51.3670282 }}
      >
        {null}
      </MapComponent>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return mounted ? (
    <div className="flex flex-col -mb-5 -mx-5 sm:mx-0 h-full">
      {step === 1 && (
        <>
          <div className="flex-1 min-h-0">{MemoMap || null}</div>
          <div className="flex mt-5 items-center px-5 md:px-0">
            <span className="address flex-1 min-w-0 ml-4 overflow-hidden overflow-ellipsis whitespace-nowrap">
              {address.address}
            </span>
            <button
              className="mr-auto bg-green-400 text-white font-bold rounded-lg text-base border-b-2 px-7 h-14 border-green-800"
              type="button"
              onClick={() => setStep(2)}
            >
              تایید و ادامه
            </button>
          </div>
        </>
      )}

      {step === 2 && (
        <>
          {loading ? (
            <SVGLoader className="w-7 h-7 text-neutral-500" />
          ) : (
            <>
              <div className="flex flex-wrap px-5 md:p-0 md:-mx-2.5">
                <div className="form-row px-2.5 w-full mb-6">
                  <label className="mb-2 cursor-pointer block font-bold text-lg text-gray-600">آدرس کامل:</label>
                  <textarea
                    placeholder="نشانی دقیق خود را وارد کنید..."
                    value={address?.address}
                    onChange={(e) => {
                      setAddressDetails(e.target.value);
                    }}
                    className="w-full border border-transparent h-20 resize-none hover:border-gray-500 hover:border-opacity-40 focus:border-primary transition-all bg-neutral-100 rounded-lg px-3 py-2 text-base placeholder-current placeholder-opacity-100 text-gray-500"
                  />
                </div>

                <div className="form-row mb-6 px-2.5 w-1/2">
                  <label className="mb-2 cursor-pointer block font-bold text-lg text-gray-600">انتخاب استان:</label>
                  <SearchableSelect
                    value={address.state}
                    setValue={setProvince}
                    options={provinces.filter((prov) => !prov.disabled)}
                    placeholder="انتخاب استان"
                    onChange={(province) => {
                      setCity('');
                      if (province && province.cities) {
                        setCities(
                          province.cities.map((city: any) => ({
                            label: city.name,
                            value: city.name,
                          })),
                        );
                      }
                    }}
                  />
                </div>
                <div className="form-row mb-6 px-2.5 w-1/2">
                  <label className="mb-2 cursor-pointer block font-bold text-lg text-gray-600">انتخاب شهر:</label>
                  <SearchableSelect value={address.city} setValue={setCity} placeholder="انتخاب شهر" options={cities} />
                </div>
              </div>

              <div className="flex px-5 md:px-0 items-center mt-auto">
                <button
                  type="button"
                  onClick={() => setStep(1)}
                  className="underline underline-offset-4 text-neutral-600"
                >
                  بازگشت و اصلاح آدرس روی نقشه
                </button>
                <button
                  className="mr-auto bg-green-400 text-white font-bold rounded-lg text-base border-b-2 px-7 h-14 border-green-800"
                  type="button"
                  onClick={onFinish}
                >
                  ذخیره آدرس و ادامه
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  ) : null;
};

export default MapSelector;
