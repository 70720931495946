import AnimatedNumber from 'animated-number-react';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import useSWR from 'swr';

import { inspectOrderAddons } from '../../../api/services';
import { numberFormat } from '../../../helpers/formatters';
import CheckBox from '../../Forms/Checkbox';
import PageModal from '../../PageModal';
import Modal from '../../Widgets/Modal';
import { useInspectRequestData } from '../InspectRequestProvider';

interface IOrderAddonsProps {
  setStep(step: number): void;
}

const OrderAddons = ({ setStep }: IOrderAddonsProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [termsAgreed, setTermsAgreed] = useState<boolean>(false);
  const [plateChangeModalVisible, setPlateChangeModalVisible] = useState<boolean>(false);
  const [contractValueField, setContractValueField] = useState<string>('');
  const {
    legalConsultant,
    plateChange,
    contractValue,
    orderNote,
    setOrderNote,
    setContractValue,
    setLegalConsultant,
    setPlateChange,
    setOrder,
  } = useInspectRequestData();

  const { data: plateChangeDescription, error: plateChangeError } = useSWR(`/wp/v2/pages/?slug=plate-change`);

  useEffect(() => {
    if (!legalConsultant) {
      setContractValue(0);
      setContractValueField('');
    }
  }, [legalConsultant, setContractValue]);

  useEffect(() => {
    setContractValueField(contractValue ? numberFormat(contractValue) : '');
  }, [contractValue]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        if (!termsAgreed) {
          toast.error('شما باید با قوانین و مقررات خدمات کاردان موتورز موافقت کنید');
          return;
        }

        setSubmitting(true);

        inspectOrderAddons({
          legalConsultant,
          plateChange,
          contractValue,
          orderNote,
          termsAgreed,
        }).then(
          ({ data }) => {
            setSubmitting(false);
            setOrder(data);
            setStep(4);
          },
          ({ response }) => {
            setSubmitting(false);
            toast.error(response.data.message);
          },
        );
      }}
      className="relative"
    >
      {submitting && <div className="blockUI" />}
      <h2 className="text-3xl leading-none mb-7 text-center font-black text-dark-800">خدمات حقوقی و تعویض پلاک</h2>

      <PageModal
        render={(clickHandler) => (
          <p
            onClick={clickHandler}
            className="bg-green-100 cursor-pointer text-green-400 text-center text-sm font-black block rounded py-2.5 leading-none mb-10 px-3.5"
          >
            از ابتدای کار تا مرحله ی آخر و لحظه ی تحویل خودرو با شما هستیم.
          </p>
        )}
        slug="legal-consultant-introduction"
      />

      <div className="flex items-center leading-none mb-11">
        <label className="text-lg flex items-center cursor-pointer font-medium text-dark-340">
          <CheckBox
            checked={legalConsultant}
            onChange={() => setLegalConsultant(!legalConsultant)}
            value="yes"
            className="ml-3"
          />
          قولنامه و نظارت حقوقی میخواهم.
        </label>

        <PageModal
          render={(clickHandler) => (
            <button onClick={clickHandler} type="button" className="text-green-400 mr-2">
              توضیحات
            </button>
          )}
          slug="legal-supervision-details"
        />
      </div>

      {legalConsultant && (
        <div className={`legal-stuff ${legalConsultant ? '' : 'opacity-30'}`}>
          <label className="block text-center font-bold text-xl text-neutral-900 mb-1">مبلغ مورد معامله (تومان)</label>

          <div className="primary-field mb-5">
            <input
              type="text"
              value={contractValueField}
              inputMode="numeric"
              onChange={(e) => {
                const value = parseFloat(e.target.value.replace(/\D/g, ''));
                setContractValue(value);
                // setContractValueField(value ? numberFormat(value) : '');
              }}
              disabled={!legalConsultant}
              className="text-center no-icon tracking-widest appearance-none"
              placeholder=""
            />
          </div>

          <p className="text-center mb-8 flex items-center justify-center">
            <span className="text-lg text-neutral-600 ml-1">هزینه:‌</span>
            <strong className="text-green-400 font-black text-4xl">
              <AnimatedNumber value={contractValue && contractValue * 0.01} formatValue={numberFormat} delay={700} />
              <small className="text-2xl mr-1">تومان</small>
            </strong>
          </p>
        </div>
      )}

      <div>
        <div className="flex items-center leading-none mb-11">
          <label className="text-lg flex items-center cursor-pointer font-medium text-dark-340">
            <CheckBox
              checked={plateChange}
              onChange={() => {
                if (!plateChange) {
                  setPlateChangeModalVisible(true);
                } else {
                  setPlateChange(false);
                }
              }}
              value="yes"
              className="ml-3"
            />
            تعویض پلاک میخواهم.
          </label>

          {plateChange && <span className="text-green-400 mr-2">+2 میلیون تومان</span>}

          {plateChangeModalVisible && (
            <Modal
              width="500px"
              animation="slide"
              title="شرایط تعویض پلاک"
              toggle={setPlateChangeModalVisible}
              closable
              tapClose
            >
              <div className="flex min-h-0 h-full flex-col">
                <div className="flex-1 -mx-5 px-5 overflow-y-auto overflow-x-hidden mb-4">
                  <div
                    className="post-content text-base leading-7 font-light text-justify text-dark-900"
                    dangerouslySetInnerHTML={{ __html: plateChangeDescription[0]?.content?.rendered }}
                  />
                </div>

                <div className="text-center mb-6 text-sm">
                  هزینه خدمات تعویض پلاک:
                  <strong className="text-green-400 mt-2 block font-black text-4xl">
                    2,000,000
                    <small className="text-2xl mr-1">تومان</small>
                  </strong>
                </div>

                <div className="mt-auto">
                  <button
                    type="button"
                    className="button primary mb-3 block w-full"
                    onClick={() => {
                      setPlateChange(true);
                      setPlateChangeModalVisible(false);
                    }}
                  >
                    شرایط را خوانده ام و میپذریم
                  </button>
                  <button
                    type="button"
                    className="block text-red-800 text-xl font-bold bg-red-100 bg-opacity-50 rounded-lg py-4 w-full"
                    onClick={() => {
                      setPlateChangeModalVisible(false);
                    }}
                  >
                    لغو و بازگشت
                  </button>
                </div>
              </div>
            </Modal>
          )}

          {/*
          <PageModal
            render={(clickHandler) => (
              <button onClick={clickHandler} type="button" className="text-green-400 mr-2">
                توضیحات
              </button>
            )}
            slug="plate-change-details"
          /> */}
        </div>

        <div className="form-row mb-6">
          <label className="block text-center font-bold text-xl text-neutral-900 mb-1">توضیحات تکمیلی (اختیاری)</label>

          <div className="primary-field">
            <textarea
              value={orderNote}
              onChange={(e) => {
                setOrderNote(e.target.value);
              }}
              className="font-normal"
              placeholder="اگر توضیحات یا نکات خاصی در مورد خودروی مورد نظر دارید مثل انتخاب های دیگر
اولویت های رنگ و بدنه و ... در این کادر وارد نمایید."
            />
          </div>
        </div>

        <div className="flex items-center leading-none mb-11">
          <label className="text-lg flex items-center cursor-pointer font-bold text-dark-340">
            <CheckBox
              checked={termsAgreed}
              onChange={() => setTermsAgreed(!termsAgreed)}
              value="yes"
              className="ml-3"
            />
            <PageModal
              render={(clickHandler) => (
                <button onClick={clickHandler} type="button" className="text-green-400 ml-2">
                  شرایط و مقررات
                </button>
              )}
              slug="terms-of-services"
            />
            را خوانده ام و قبول میکنم
          </label>
        </div>
      </div>

      <div className="flex">
        <button type="button" onClick={() => setStep(2)} className="button cancel primary right block w-1/2">
          بازگشت
        </button>
        <button disabled={submitting} type="submit" className="button primary left block w-1/2">
          ثبت درخواست
        </button>
      </div>
    </form>
  );
};

export default OrderAddons;
