import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

import IAddress from '../../interface/IAdress';
import ICarModel from '../../interface/ICarModel';
import IOrder from './IOrder';

export interface IInspectRequestData {
  fullName: string;
  phone: string;
  sellerPhone: string;
  carModel: ICarModel | undefined;
  address: IAddress;
  needsPhoneVerification: boolean;
  legalConsultant: boolean;
  plateChange: boolean;
  orderNote: string;
  contractValue: number;
  order: IOrder | undefined;
  setNeedsPhoneVerification(yesno: boolean): void;
  setFullName(fullName: string): void;
  setPhone(phone: string): void;
  setSellerPhone(phone: string): void;
  setCarModel(model: ICarModel): void;
  setAddress(address: IAddress): void;
  setLegalConsultant(yesno: boolean): void;
  setPlateChange(yesno: boolean): void;
  setContractValue(amount: number): void;
  setOrderNote(note: string): void;
  setOrder(order: IOrder): void;
}

const InspectRequestContext = createContext<IInspectRequestData>({} as IInspectRequestData);

export interface InspectRequestProviderProps {
  children: ReactNode;
}

const InspectRequestProvider = ({ children }: InspectRequestProviderProps) => {
  const [fullName, setFullName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [sellerPhone, setSellerPhone] = useState<string>('');
  const [needsPhoneVerification, setNeedsPhoneVerification] = useState<boolean>(true);
  const [carModel, setCarModel] = useState<ICarModel>();
  const [order, setOrder] = useState<IOrder>();
  const [legalConsultant, setLegalConsultant] = useState<boolean>(false);
  const [plateChange, setPlateChange] = useState<boolean>(false);
  const [contractValue, setContractValue] = useState<number>(0);
  const [orderNote, setOrderNote] = useState<string>('');
  const [address, setAddress] = useState<IAddress>({
    latLng: { lat: 0, lng: 0 },
    address: '',
    state: '',
    city: '',
  });

  const memorizedValue = useMemo<IInspectRequestData>(
    () => ({
      fullName,
      phone,
      sellerPhone,
      carModel,
      address,
      needsPhoneVerification,
      legalConsultant,
      plateChange,
      contractValue,
      orderNote,
      order,
      setFullName,
      setPhone,
      setSellerPhone,
      setCarModel,
      setAddress,
      setNeedsPhoneVerification,
      setLegalConsultant,
      setPlateChange,
      setContractValue,
      setOrderNote,
      setOrder,
    }),
    [
      address,
      carModel,
      contractValue,
      fullName,
      legalConsultant,
      needsPhoneVerification,
      order,
      orderNote,
      phone,
      plateChange,
      sellerPhone,
    ],
  );

  return <InspectRequestContext.Provider value={memorizedValue}>{children}</InspectRequestContext.Provider>;
};

export const useInspectRequestData = () => useContext<IInspectRequestData>(InspectRequestContext);

export default InspectRequestProvider;
