import { useState } from 'react';

import InspectRequestProvider from '../InspectRequestProvider';
import Checkout from './Checkout';
import InitOrder from './InitOrder';
import OrderAddons from './OrderAddons';
import PickTime from './PickTime';
import VerifyPhone from './VerifyPhone';

const InspectRequestForm = () => {
  const [step, setStep] = useState<number>(1);

  return (
    <InspectRequestProvider>
      {step === 1 && <InitOrder setStep={setStep} />}
      {step === 2 && <VerifyPhone setStep={setStep} />}
      {step === 3 && <OrderAddons setStep={setStep} />}
      {step === 4 && <PickTime setStep={setStep} />}
      {step === 5 && <Checkout setStep={setStep} />}
    </InspectRequestProvider>
  );
};

export default InspectRequestForm;
