import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import Icon from '../common/Icon';

export interface ISearchableSelectOptionProps {
  label: ReactNode;
  value: string | number;
  disabled?: boolean;
  [x: string | number | symbol]: any;
}

export interface ISearchableSelectProps {
  placeholder?: string;
  value: string | number;
  setValue(val: string | number): void;
  onChange?: (opt: ISearchableSelectOptionProps) => void;
  options: ISearchableSelectOptionProps[];
}

const SearchableSelect = ({ options = [], value = '', setValue, onChange, placeholder }: ISearchableSelectProps) => {
  const [listShown, setListShown] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const wrapper = useRef<HTMLDivElement>(null);

  const closeList = useCallback(
    (e: MouseEvent) => {
      if (listShown && wrapper.current && !wrapper.current.contains(e.target as Node)) {
        setListShown(false);
      }
    },
    [listShown],
  );

  useEffect(() => {
    document.body.addEventListener('click', closeList);

    return () => {
      document.body.removeEventListener('click', closeList);
    };
  }, [closeList]);

  return (
    <div ref={wrapper} className="relative">
      <div
        onClick={() => setListShown(!listShown)}
        className={`w-full relative flex items-center resize-none border border-transparent ${
          listShown ? 'border-primary' : 'hover:border-gray-400 hover:border-opacity-50'
        } bg-neutral-100 rounded-lg px-3 pl-1.5 py-2 text-base placeholder-current placeholder-opacity-100 text-gray-500 `}
      >
        {!search && <>{value ? value : <>{placeholder ? placeholder : null}</>}</>}

        <input
          className="absolute top-0 right-0 block w-full h-full appearance-none bg-transparent px-3"
          type="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        <span className="mr-auto">
          <Icon name="expand" className="w-4 h-4" />
        </span>
      </div>

      {listShown && (
        <div className="options absolute top-full mt-1.5 z-high bg-white inset-x-0 shadow-md border border-neutral-300 rounded-md h-40">
          <ul className="overflow-auto max-h-full">
            {options.map((option, i) => (
              <React.Fragment key={i}>
                {(!search || option.value.toString().match(search)) && (
                  <li
                    onClick={() => {
                      if (!option.disabled) {
                        setSearch('');
                        setValue(option.value);
                        if (onChange) {
                          onChange(option);
                        }
                        setListShown(false);
                      }
                    }}
                    className={`py-3 font-medium text-sm px-3 ${
                      option.value === value ? 'bg-blue-500 text-white' : 'hover:bg-blue-100 hover:bg-opacity-60'
                    } ${option.disabled ? 'opacity-50' : ''}`}
                  >
                    {option.label}
                  </li>
                )}
              </React.Fragment>
            ))}

            {options.length === 0 && <li className="text-center py-3 text-gray-500">گزینه ای یافت نشد</li>}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchableSelect;
