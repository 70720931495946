import { useState } from 'react';
import { toast } from 'react-hot-toast';

import { inspectOTPVerify } from '../../../api/services';
import { numberFormat } from '../../../helpers/formatters';
import PageModal from '../../PageModal';
import { useInspectRequestData } from '../InspectRequestProvider';

interface IVerifyPhoneProps {
  setStep(step: number): void;
}

const VerifyPhone = ({ setStep }: IVerifyPhoneProps) => {
  const [otp, setOtp] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { phone, carModel, setNeedsPhoneVerification } = useInspectRequestData();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        setSubmitting(true);
        inspectOTPVerify({ phone, otp }).then(
          ({ data }) => {
            setSubmitting(false);

            if (data.user_verified) {
              setNeedsPhoneVerification(false);
            }

            setStep(3);
          },
          ({ response }) => {
            setSubmitting(false);
            toast.error(response.data.message);
          },
        );
      }}
      className="relative"
    >
      {submitting && <div className="blockUI" />}
      <h2 className="text-3xl leading-none mb-7 text-center font-black text-dark-800">هزینه ی کارشناسی این خودرو</h2>

      <strong className="font-black mb-3.5 text-center text-4xl text-green-400 block">
        {carModel && numberFormat(carModel.inspect_price) + ' تومان'}
      </strong>

      <PageModal
        render={(clickHandler) => (
          <button
            onClick={clickHandler}
            type="button"
            className="table text-lg font-black text-neutral-350 mb-8 mx-auto"
          >
            چه مواردی چک می شوند؟
          </button>
        )}
        slug="car-inspection-items"
      />

      <label className="block text-center font-bold text-xl text-neutral-900 mb-1">
        کد تایید پیامک شده را وارد نمایید:
      </label>

      <div className="primary-field mb-5">
        <input
          type="text"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          className="text-center no-icon tracking-widest"
          pattern="[0-9]*"
          inputMode="numeric"
          maxLength={6}
          placeholder="-  -  -  -"
        />
      </div>

      {/* <label className="block text-center font-bold text-xl text-neutral-900 mb-1">توضیحات (اختیاری)</label>

      <div className="primary-field mb-5">
        <textarea
          className="text-center"
          placeholder="اگر توضیحات یا نکات خاصی دارید که بهتر است کارشناس بداند، لطفا در این کادر تایپ نمایید."
        />
      </div> */}

      <div className="flex">
        <button type="button" onClick={() => setStep(1)} className="button cancel primary right block w-1/2">
          بازگشت
        </button>
        <button type="submit" disabled={submitting} className="button primary left block w-1/2">
          تایید شماره
        </button>
      </div>
    </form>
  );
};

export default VerifyPhone;
